import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 条件查询快递信息列表
export const getPageList = (params) =>
  request({
    url: REST_SERVICE.express.getPageList,
    method: 'get',
    params
  });

// 根据外网业务号和邮寄类型获取邮寄信息详情
export const findByYwhAndPostType = (params) =>
  request({
    url: REST_SERVICE.express.findByYwhAndPostType,
    method: 'get',
    params
  });

// 保存快递订单信息
export const saveExpressInfo = (params) =>
  request({
    url: REST_SERVICE.express.saveExpressInfo,
    method: 'post',
    data: params
  });

// 通过办事大厅代码查询办事大厅
export const findByOfficeCode = (params) =>
  request({
    url: REST_SERVICE.express.findByOfficeCode,
    method: 'get',
    data: params
  });
