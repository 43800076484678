<template>
  <e-dialog :config="openCheckInfo">
    <template slot="content">
      <el-form
        ref="expressForm"
        :model="checkFormData"
        :disabled="formIsDisabled"
        label-width="110px"
        label-position="left"
        :rules="editFormRules"
      >
        <h3
          class="title margin-bottom-medium"
        >寄件人信息({{ formData.postType === 'SMQJ' ? '我的' : '大厅' }})</h3>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人：" prop="senderName">
              <div>{{ checkFormData.senderName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话：" prop="senderPhone">
              <div>{{ checkFormData.senderPhone }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="登记机构:" prop="djjg" v-if="formData.postType !== 'SMQJ'">
              <div>{{ checkFormData.djjg }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址：" prop="senderAddr">
              <div>{{ checkFormData.senderAddr }}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <h3
          class="title margin-bottom-medium margin-top-medium"
        >收件人信息({{ formData.postType === 'SMQJ' ? '大厅' : '我的' }})</h3>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人:" prop="receiverName">
              <div>{{ checkFormData.receiverName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话:" prop="receiverPhone">
              <div>{{ checkFormData.receiverPhone }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="登记机构:" prop="djjg" v-if="formData.postType === 'SMQJ'">
              <div>{{ checkFormData.djjg }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址:" prop="receiverAddr">
              <div>{{ checkFormData.receiverAddr }}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="(formData.postType === 'SMQJ') && (type === 'edit')">
          <el-col :span="12">
            <el-form-item label="邮寄状态:" prop="postStatus">
              <span
                v-if="(checkFormData.postStatus==='DB'||!checkFormData.postStatus) && !trackingNum"
                class="font-orange"
              >待办</span>
              <span
                v-if="(checkFormData.postStatus==='DB'||!checkFormData.postStatus) && trackingNum"
                class="font-orange"
              >待签收</span>
              <span v-if="checkFormData.postStatus === 'JSZ'" class="font-red">寄送中</span>
              <span v-if="checkFormData.postStatus === 'WC'" class="font-green">已完成</span>
              <span v-if="checkFormData.postStatus === 'QX'">已取消</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="快递单号:" prop="trackingNum" class="trackingnumber">
              <el-input v-model="checkFormData.trackingNum" placeholder="请输入快递单号" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="快递员姓名:" prop="courier">
              <el-input v-model="checkFormData.courier" placeholder="请输入快递员姓名" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="快递员电话:" prop="courierPhone">
              <el-input v-model="checkFormData.courierPhone" placeholder="请输入快递员电话" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="寄出时间:" prop="sendingTime">
              <span>{{ checkFormData.sendingTime | formatDate }}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="(formData.postType === 'ZSYJ') && (type === 'edit')">
          <h3 class="title margin-bottom-medium margin-top-medium">签收信息</h3>
          <el-col :span="12">
            <el-form-item label="签收人:" prop="signatory">
              <el-input v-model="checkFormData.signatory" placeholder="请输入签收人" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="签收电话:" prop="signatoryPhone">
              <el-input v-model="checkFormData.signatoryPhone" placeholder="请输入签收电话" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="type==='check'">
          <h3 class="title margin-bottom-medium margin-top-medium">签收信息</h3>

          <el-col :span="12">
            <el-form-item
              class="trackingnumber"
              label="快递单号:"
              prop="trackingNum"
              v-if="checkFormData.trackingNum"
            >
              <span>{{ checkFormData.trackingNum }}</span>
            </el-form-item>
            <el-form-item label="快递单号:" v-if="!checkFormData.trackingNum" class="trackingnumber">
              <span>暂无快递单号</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="签收人:" prop="signatory">
              <span v-if="checkFormData.postStatus === 'WC'">{{ checkFormData.signatory }}</span>
              <span v-if="checkFormData.postStatus !== 'WC'">该订单尚未完成</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="签收电话:"
              prop="signatoryPhone"
              v-if="checkFormData.postStatus === 'WC'"
            >
              <span>{{ checkFormData.signatoryPhone }}</span>
            </el-form-item>
            <el-form-item label="签收电话:" v-if="checkFormData.postStatus !== 'WC'">
              <span>该订单尚未完成</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="签收时间:"
              prop="deliveryTime"
              v-if="checkFormData.postStatus === 'WC'"
            >
              <span>{{checkFormData.deliveryTime | formatDate('yyyy-MM-dd hh:mm:ss') }}</span>
            </el-form-item>
            <el-form-item label="签收时间:" v-if="checkFormData.postStatus !== 'WC'">
              <span>该订单尚未完成</span>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="邮寄状态:" prop="postStatus">
              <span
                v-if="(checkFormData.postStatus==='DB'||!checkFormData.postStatus) && !trackingNum"
                class="font-orange"
              >待办</span>
              <span
                v-if="(checkFormData.postStatus==='DB'||!checkFormData.postStatus) && trackingNum"
                class="font-orange"
              >待签收</span>
              <span v-if="checkFormData.postStatus === 'JSZ'" class="font-red">寄送中</span>
              <span v-if="checkFormData.postStatus === 'WC'" class="font-green">已完成</span>
              <span v-if="checkFormData.postStatus === 'QX'">已取消</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btns">
        <el-button size="medium" @click="closeCheckInfo">关闭</el-button>
        <el-button v-if="type==='edit'" type="primary" size="medium" @click="saveHandle">保存</el-button>
      </div>
    </template>
  </e-dialog>
</template>

<script>
import { saveExpressInfo } from 'iebdc/api/express.js';
import { formatDate } from '../../../../filters/index.js';
export default {
  name: 'CheckInfo',
  props: { openCheckInfo: Object },
  data() {
    const validatePhone = async (rule, value, callback) => {
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/;
      if (!value) {
        return callback(new Error('请输入电话号码'));
      }
      if (!re.test(value)) {
        return callback(new Error('请输入合法的电话号码'));
      }
    };
    return {
      type: '',
      formData: {},
      formIsDisabled: false,
      //弹窗详情信息
      checkFormData: {
        senderName: '', //联系人
        senderPhone: '', //电话
        senderAddr: '', //地址
        receiverName: '', //联系人
        receiverPhone: '', //电话
        djjg: '', //登记机构
        receiverAddr: '', //地址
        trackingNum: '', //快递单号
        signatory: '', //签收人
        signatoryPhone: '', //签收电话
        deliveryTime: '', // 签收时间
        postStatus: '', // 邮寄状态
        courier: '', //快递员
        courierPhone: '', //快递员电话
        sendingTime: '', //寄出时间
      },
      trackingNum: '',
      editFormRules: {
        signatoryPhone: [{ required: true, validator: validatePhone, trigger: 'change' }],
        courierPhone: [{ required: true, validator: validatePhone, trigger: 'change' }],
        trackingNum: [{ required: true, message: '请输入快递单号', trigger: 'change' }],
      },
    };
  },
  methods: {
    getDate(date) {
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    },
    success() {
      this.openCheckInfo.visible = false;
      this.$emit('success');
    },
    // 父组件中$ref
    open(type, row) {
      this.type = type;
      this.formData = row;
      this.trackingNum = row.trackingNum;
      // console.log(this.formData);
    },
    saveHandle() {
      this.$refs.expressForm.validate((valid) => {
        if (valid) {
          if (this.formData.postType === 'SMQJ') {
            this.checkFormData.postStatus = 'JSZ';
            this.checkFormData.sendingTime = this.getDate(new Date());
          } else {
            this.checkFormData.postStatus = 'WC';
            this.checkFormData.deliveryTime = this.getDate(new Date());
          }

          saveExpressInfo(this.checkFormData).then((res) => {
            if (res.success) {
              this.success();
              //调用父组件方法重新加载当前页数据
              this.$parent.query();
              this.$message.success('保存成功');
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.warning('请先完善表单');
          return false;
        }
      });
    },

    // 关闭弹窗重置表单
    closeCheckInfo() {
      this.$nextTick(() => {
        this.$refs.expressForm.clearValidate();
        this.$refs.expressForm.resetFields();
        // this.formData = Object.assign({}, row);
      });
      this.openCheckInfo.visible = false;
    },
  },
  filters: {
    formatDate() {
      let date = new Date();
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    },
  },
  computed: {},
  watch: {
    formData: function (val) {
      this.checkFormData = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
/deep/ .el-dialog {
  /deep/ .el-form-item__label {
    padding: 0;
  }
}
/deep/ .el-input {
  width: 90%;
}
.title {
  font-weight: bold;
  color: #333333;
  font-family: Microsoft YaHei;
}
.margin-bottom-medium {
  margin-bottom: 27px;
}
.margin-top-medium {
  margin-top: 30px;
}
/deep/ .trackingnumber {
  font-weight: 600;
}
.font-orange {
  color: orange;
}
.font-red {
  color: red;
}
.font-green {
  color: green;
}
.bottom-btns {
  text-align: center;
  margin-top: 20px;
}
</style>
