<template>
  <div class="content">
    <common-breadcrumb />
    <div class="table-wrap">
      <div class="top-tab">
        <el-tabs v-model="activeName" @tab-click="handleTabClick" class="tab-text">
          <!-- 添加 我的寄件 的面板 -->
          <el-tab-pane label="我的寄件" name="SMQJ"></el-tab-pane>
          <!-- 添加 我的收件 的面板 -->
          <el-tab-pane label="我的收件" name="ZSYJ"></el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <!-- 查询 -->
        <e-search :filterGroup="filterGroup" :btnsConfig="btnsConfig" @search="search" @reset="reset"></e-search>
        <!-- 表格区域 -->
        <e-table
          ref="expressRef"
          :tableList="tableList"
          :columns="columns"
          :operates="operates"
          :options="options"
          @afterCurrentPageClick="afterCurrentPageClickHandle"
        ></e-table>
        <!-- :cell-class-name="addCellClass"
                  :cell-style="cellStyle"
        -->
      </div>
    </div>
    <CheckInfo ref="editDialog" :openCheckInfo="openCheckInfo" />
  </div>
</template>

<script>
import ESearch from '@/components/e-search';
import ETable from '@/components/e-table';
import { getPageList } from 'iebdc/api/express.js';
import utils from '@/utils/utils';
import CheckInfo from './CheckInfo';

export default {
  name: 'express',
  data() {
    return {
      type: 'check',
      btnsConfig: {
        clearBtnText: '清除',
        showResetBtn: true,
        showMoreBtn: false,
      },

      // 打开新增弹框配置
      openCheckInfo: {
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false,
      },
      formData: {},

      activeName: 'SMQJ',
      filterGroup: [
        {
          label: '快递单号',
          key: 'trackingNum',
          type: 'input',
          placeholder: '请输入快递单号',
          value: '',
        },
        {
          type: 'dateRange',
          label: '邮寄时间',
          options: [
            {
              placeholder: '邮寄时间起',
              key: 'sendingTimeBegin',
              value: '',
              valueformat: 'yyyy-MM-dd',
            },
            {
              placeholder: '邮寄时间止',
              key: 'sendingTimeEnd',
              value: '',
              valueformat: 'yyyy-MM-dd',
            },
          ],
        },
      ],
      tableList: [],
      columns: [
        {
          width: '140px',
          prop: 'ywh',
          label: '业务号',
        },
        {
          width: '140px',
          prop: 'flowName',
          label: '业务名称',
        },
        {
          width: '140px',
          prop: 'bsdt',
          label: '办事大厅',
        },
        {
          prop: 'postStatus',
          label: '邮寄状态',
          type: 'dic',
          formatter: (row) => {
            // console.log(row, 'row');
            // 待签收：1.非完成、有快递单号、我的寄件页签显示。
            // 2.没有快递状态且有快递单号
            if (
              (row.postStatus !== 'WC' && row.trackingNum && row.postType === 'SMQJ') ||
              (!row.postStatus && row.trackingNum)
            ) {
              return '待签收';
            } else if (row.postStatus === 'JSZ' && row.postType === 'ZSYJ') {
              return '寄送中';
            } else if (row.postStatus === 'WC') {
              return '已完成';
            } else if (row.postStatus === 'QX') {
              return '已取消';
            } else if ((!row.postStatus && !row.trackingNum) || (row.postStatus === 'DB' && !row.trackingNum)) {
              return '待办';
            } else {
              return '无信息';
            }
          },
        },
      ],

      operates: {
        //表格操作栏
        width: '200px',
        fixed: 'right',
        list: [
          {
            show: true,
            spanType: 'button',
            id: '1',
            label: '查看',
            type: 'primary',
            method: (key, row) => {
              // console.log(row);
              this.type = 'check';
              this.openCheckInfo.title = '我的邮寄 > 查看';
              this.$refs.editDialog.open('check', row);
              this.openCheckInfo.visible = true;
            },
            showCallback: () => {
              return true;
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '2',
            label: '编辑',
            type: 'success',
            method: (key, row) => {
              this.type = 'edit';
              this.openCheckInfo.title = '我的邮寄 > 编辑';
              this.$refs.editDialog.open('edit', row);
              this.openCheckInfo.visible = true;
              this.$nextTick(() => {
                this.formData = Object.assign({}, row);
              });
            },
            showCallback: (row) => {
              return (
                (this.activeName === 'SMQJ' && row.postStatus !== 'WC' && !row.trackingNum) ||
                (this.activeName === 'ZSYJ' && row.postStatus === 'JSZ')
              );
            },
          },
        ],
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: true,
      },
    };
  },
  components: {
    ESearch,
    ETable,
    CheckInfo,
  },
  mounted() {
    this.init();
    // console.log(IEBDC_USER_DATA.userId);
  },
  methods: {
    // 初始化数据
    init(type) {
      this.params = {
        nCurrent: 0,
        areaCode: 640000,
        userId: '5e5e1015f2116c16300cd237', //IEBDC_USER_DATA.userId, //'5e5e1015f2116c16300cd237',
        postType: type || 'SMQJ',
      };
      this.query();
    },
    query() {
      console.log(this.params);
      getPageList(this.params).then((res) => {
        if (res.success && res.data) {
          this.$refs.expressRef.setTableList(res.data.page);
          this.$refs.expressRef.setTotal(res.data.recordCount);
          this.tableList = res.data.page;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 页签
    handleTabClick() {
      this.params.postType = this.activeName;
      this.afterCurrentPageClickHandle(1);
      console.log(this.activeName);
    },
    // 查询
    search(queryParams) {
      let searchParams = Object.assign(this.params, queryParams, { nCurrent: 0 });
      this.query(searchParams);
      this.$refs.expressRef.setCurrentPage(1);
    },
    // 清除(重置)
    reset() {
      this.init(this.activeName);
      this.$refs.expressRef.setCurrentPage(1);
    },
    // 翻页
    afterCurrentPageClickHandle(val) {
      this.$refs.expressRef.setCurrentPage(val);
      this.params.nCurrent = val - 1;
      this.query();
    },

    // //修改单元格样式的方法
    // addCellClass({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 4) {
    //     if (row.postStatus === 'WC') {
    //       return 'cell-green';
    //     }
    //   }
    // },

    //根据测试结果动态调整单元格样式 完成-绿色 待签收、待办-橘色
    // cellStyle({ row, column, rowIndex, columnIndex }) {
    //   let cellStyle;
    //   switch (this.row.postStatus) {
    //     case 'WC':
    //       cellStyle = 'background: green;color:white';
    //       break;
    //     case 'JSZ':
    //       cellStyle = 'background: red;color:white';
    //       break;
    //     case 'QX':
    //       cellStyle = 'background: #aaa;color:white';
    //       break;
    //     default:
    //       cellStyle = '';
    //   }
    //   if (columns.label == '邮寄状态') return cellStyle;
    // },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 20px;
  padding: 25px 25px 20px 25px;
  width: 100%;
  /*min-height: 555px;*/
  height: auto;
  background: #ffffff;
  .bread-menu-wrap {
    line-height: 40px;
    font-size: 13px;
    .bread-menu-wrap-right {
      span {
        color: #145ccd;
        cursor: pointer;
      }
    }
  }
  .table-wrap {
    /deep/ .e-table {
      height: 630px;
    }
    .top-tab {
      height: 42px;
      // border-bottom: 1px solid #e7e7e7;
      .tab-text {
        padding: 0px 20px;
        line-height: 42px;
        color: #0168b7;
        font-size: 16px;
      }
    }
    .table-filter {
      margin: 20px 0;
      /deep/ .flex-column {
        .left-panel {
          .filter-item:nth-child(1) {
            .el-select:nth-child(1) {
              width: 100px !important;
            }
          }
        }
      }
    }
  }
}
</style>
